





























































import Vue from "vue";
import YesNotSelection from "@/components/YesNotSelection.vue";
import stepFielfdMixin from "../stepFielfdMixin";

export default Vue.extend({
  components: { YesNotSelection },
  name: "alergies",
  mixins: [stepFielfdMixin],
  data() {
    return {
      fields: {
        localAnesthetics: false,
        penicillinAnyAntibiotics: false,
        sulfaDrugs: false,
        barbiturates: false,
        sedatives: false,
        lodine: false,
        aspirin: false,
        anyMetal: false,
        latexRubber: false,
        otherAlergies: "",
      },
    };
  },
});
